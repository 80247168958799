.main-container {
  min-height: 100vh;
  background-color: #ffffff;
}

.header {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 1024px) {
    max-height: 10vh;
    flex-direction: row;
  }

  .logo-container {
    flex-basis: 20%;

    .nav-link {
      display: flex;
      justify-content: center;

      @media (min-width: 1024px) {
        display: initial;
      }

      img {
        max-height: 100%;
        max-width: 60%;

        @media (min-width: 1024px) {
          max-width: 100%;
        }
      }
    }
  }

  .links {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1024px) {
      justify-content: initial;
      gap: 30px;
    }

    .nav-link-container {
      padding: 10px;

      .nav-link {
        text-decoration: none;
        color: #175DD4;
        display: inline-flex;
        flex-direction: column;

        // &:hover {
        //   color: #104092;
        //   // font-style: italic;
        //   font-weight: 700;
        // }

        // &::after {
        //   content: attr(data-text);
        //   content: attr(data-text) / "";
        //   height: 0;
        //   visibility: hidden;
        //   overflow: hidden;
        //   user-select: none;
        //   pointer-events: none;
        //   font-weight: 700;
        // }

        span {
          &::after {
            border-bottom: 3px solid #175DD4;
            content: "";
            display: block;
            transform: scaleX(0);
            transform-origin: 100% 50%;
            transition: transform .3s ease-in-out;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: 0 50%;
          }
        }
      }
    }
  }
}

.home {
  &__body {
    color: #175DD4;
    // min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    h1 {
      margin-bottom: 40px;
    }
     
    .logo {
      max-width: 90%;

      @media (min-width: 1024px) {
        max-width: 50%;
      }
    }
  }
}

.gino-logan {
  max-width: 90%;
  border-radius: 30px;

  @media (min-width: 1024px) {
    max-width: 50%;
  }
}
